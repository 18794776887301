"use client";

import {
  useViewport,
  useLaunchParams,
  useQRScanner,
  useMiniApp,
  usePopup,
} from "@tma.js/sdk-react";
import { useEffect, useRef, useState } from "react";
import { sendScannedCode } from "helpers/qrcode-api";

export default function App() {
  const miniapp = useMiniApp();
  const viewport = useViewport();
  const qrScanner = useQRScanner();
  const popup = usePopup();
  const { initDataRaw } = useLaunchParams();
  const [scannerSupported, setScannerSupported] = useState(true);
  const isLoading = useRef<boolean>(false); //useState doesn't fit for sync logic

  const sendQrPayload = async (payload: string) => {
    if (!initDataRaw) return;
    isLoading.current = true;
    try {
      const response = await sendScannedCode(payload, initDataRaw);
      switch (response) {
        case "Success":
          qrScanner.close();
          break;
        case "NotFound":
          await popup.open({
            title: "Неверный QR-код",
            message: "Отсканируйте другой QR-код",
          });
          break;
        case "Expired":
          await popup.open({
            title: "QR-код устарел",
            message: "QR-код уже не актуален",
          });
          break;
        case "AlreadyScanned":
          await popup.open({
            title: "Вы уже сканировали данный QR-код",
            message: "Ищите новые QR-коды, чтобы заработать баллы",
          });
          break;
        case "Unauthorized":
          await popup.open({
            title: "Ошибка авторизации",
            message: "Пожалуйста, перезагрузите приложение",
          });
          break;
        case "UnknownError":
          await popup.open({
            title: "Неизвестная ошибка",
            message: "Попробуйте позже",
          });
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      isLoading.current = false;
    }
  };

  useEffect(() => {
    viewport?.expand();
    const scannerSupported = qrScanner.supports("open");
    setScannerSupported(scannerSupported);
    if (!scannerSupported || qrScanner.isOpened) {
      return;
    }
    qrScanner.on(
      "change:isOpened",
      (isOpened) => !isOpened && miniapp.close(false),
    );
    qrScanner.open({
      capture: (payload) => {
        if (payload.data && !isLoading.current) sendQrPayload(payload.data);
        return false;
      },
    });
  }, []);

  return (
    <div className="text-white text-center">
      {scannerSupported ? "Загрузка..." : "Отройте этот QR сканер с телефона"}
    </div>
  );
}
