"use client";

import type { PropsWithChildren } from "react";
import { SDKProvider } from "@tma.js/sdk-react";

/**
 * Root component of the whole project.
 */
export function TmaSDKLoader({ children }: PropsWithChildren) {
  return <SDKProvider acceptCustomStyles>{children}</SDKProvider>;
}
