import { QRCodeResponseType } from "types/qrcode-response";

const apiUrl = process.env.REACT_APP_API_URL!;

export async function sendScannedCode(
  code: string,
  auth: string,
): Promise<QRCodeResponseType> {
  const response = await fetch(`${apiUrl}/qr-code`, {
    method: "POST",
    body: new URLSearchParams({ code }),
    headers: { Authorization: `tma ${auth}` },
  });
  const data = (await response.json()) as { errors?: string[] };

  if (response.ok) {
    return "Success";
  }
  if (response.status === 401) {
    return "Unauthorized";
  }
  const error = data.errors?.[0];
  if (error === "qr_code_not_found") {
    return "NotFound";
  }
  if (error === "qr_code_already_scanned") {
    return "AlreadyScanned";
  }
  if (error === "qr_code_expired") {
    return "Expired";
  }
  return "UnknownError";
}
